// @flow
import { useState, useRef, useEffect } from 'react';

import fakeUuid from './fakeUuid';
import type {
    MainTask,
    SubtaskType,
    TaskSectionType,
    EditorWorkflow,
    FollowerPreference,
} from './types';
import { hasOwnProperty } from './Object';

const WORKFLOW_STORAGE_KEY = 'workflow';

export const subtaskTypes = [
    { value: 'text', label: 'Text', },
    { value: 'switch', label: 'Toggle', },
    { value: 'check', label: 'Check', },
    { value: 'timepicker', label: 'Time', },
    { value: 'datepicker', label: 'Date', },
    { value: 'select', label: 'Select', },
    { value: 'picker', label: 'Picker', },
    { value: 'tel', label: 'Telephone', },
    { value: 'radio', label: 'Radio', },
    { value: 'referring', label: 'Referring', }
];

export const subtaskAttributes = [
    { value: 'flightTracker', label: 'Flight Tracker', },
    { value: 'mandatory', label: 'Mandatory', },
    { value: 'displayOnItem', label: 'Display On Item', }
];

export const multipleOptionsUiTypes = ['select', 'picker', 'radio'];
export const referringUiType = 'referring';

export const subtaskTypeLabel = subtaskTypes.reduce((types, type) => {
    types[type.value] = type.label;

    return types;
}, {});

export function createTask(task?: $Shape<MainTask>): MainTask {
    return {
        id: fakeUuid(),
        name: '',
        sections: [],
        ...task,
    };
}

export function createSubtask(field?: $Shape<SubtaskType>): SubtaskType {
    return {
        id: fakeUuid(),
        title: '',
        uiType: subtaskTypes[0].value,
        responseOptions: [],
        attributes: [],
        mapping: '',
        ...field,
    };
}

export function createSection(section?: $Shape<TaskSectionType>): TaskSectionType {
    return {
        id: fakeUuid(),
        title: '',
        fields: [],
        ...section,
    };
}

/**
 * Hook that alerts clicks outside of a DOM element that ref gets attached to
 */
export function useClickedAway() {
    const [clickedAway, setClickedAway] = useState(false);
    const ref = useRef<HTMLElement | null>(null);

    const handleClickOutside = (event: MouseEvent) => {
        if (ref.current && ref.current instanceof Node && !ref.current.contains((event.target: any))) {
            setClickedAway(true);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);

        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    return { ref, clickedAway, };
}

/**
 * Load workflow data from sessionStorage
 */

export function loadSavedWorkflow(userId: number): EditorWorkflow | void {
    if (sessionStorage) {
        const workflowStorage = sessionStorage.getItem(WORKFLOW_STORAGE_KEY);

        if (!workflowStorage) {
            return undefined;
        }

        const workflowStorageJson = JSON.parse(workflowStorage);
        return workflowStorageJson[userId];
    }

    return undefined;
}

export function saveWorkflow(userId: number, workflowJson: EditorWorkflow) {
    if (sessionStorage) {
        const workflowStorage = sessionStorage.getItem(WORKFLOW_STORAGE_KEY);
        if (workflowStorage) {
            const workflowStorageJson = JSON.parse(workflowStorage);
            sessionStorage.setItem(WORKFLOW_STORAGE_KEY, JSON.stringify({
                ...workflowStorageJson,
                [userId]: workflowJson,
            }));
        } else {
            sessionStorage.setItem(WORKFLOW_STORAGE_KEY, JSON.stringify({
                [userId]: workflowJson,
            }));
        }
    }
}


// Convert how certain field properties are stored in the builder to
// field properties as they're structured in the backend
export function attributesToFieldProps(fieldAttributes: any, fieldUIType: any) {
    const fieldProps = fieldAttributes.reduce((props, attr) => {
        props[attr.value] = true;

        return props;
    }, {});

    if (fieldUIType === 'datepicker') {
        fieldProps.isDate = true;
    }

    if (fieldUIType === 'timepicker') {
        fieldProps.isTime = true;
    }

    return fieldProps;
}

// Convert fields properties from how they're structured in the backend
// to how certain field properties are stored in the builder
export function fieldPropsToAttributes(field: $Shape<SubtaskType>) {
    return subtaskAttributes.reduce((attributes, attrObj) => {
        if (hasOwnProperty(field, attrObj.value)) {
            attributes.push(attrObj);
        }

        return attributes;
    }, []);
}

export const transformTemplateToWorkflow = (template: any) => ({
    name: template.name,
    type: template.type,
    flat: template.flat,
    progressBar: template.progressBar,
    ownerType: template.ownerType,
    ownerId: template.ownerId,
    key: template.key,

    tasks: template.tasks.map((task) => {
        const associatedForm = template.forms.find((form) => form.key === task.details.formId);
        return {
            id: task.taskId,
            name: task.details.description,
            formId: associatedForm ? associatedForm.key : null,
            behaviors: associatedForm ? associatedForm.behaviors : null,
            sections: associatedForm && associatedForm.sections ? associatedForm.sections.map((section) => ({
                id: section.id,
                title: section.title,
                fields: section.fields.map((field) => ({
                    id: field.id,
                    title: field.title,
                    uiType: field.uiType === 'multiple' ? 'select' : field.uiType,
                    displayAs: field.displayAs,
                    responseOptions: field.responseOptions || [],
                    attributes: fieldPropsToAttributes(field),
                    mapping: field.mapping,
                    referToTaskId: field.referToTaskId,
                    referToFieldId: field.referToFieldId,
                })),
            })) : [],
        };
    }),
});

export function isNameAvailable(name: string, availableNames: string[]) {
    return !availableNames.includes(name.trim());
}

export const getFollowerPreferencesFromTemplate = (template: any): FollowerPreference[] => {
    const { casePreferences, } = template;
    if (casePreferences && casePreferences.preferences) {
        const { preferences, } = casePreferences;
        if (preferences.followers && preferences.followers.length > 0) {
            return preferences.followers;
        }
    }

    return [];
};
